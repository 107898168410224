import Modal from "../_modal";
import { isOnMobile } from "../../_utils";

class Page {

	static PAGE_CLASS;

	constructor() {
		this.modal = new Modal();
	}

	static isCurrentPage() {
		return $("html").hasClass(this.PAGE_CLASS);
	}

	isOnMobile() {
		return isOnMobile();
	}

	handleAjaxRequestError(error) {
		if (error.response?.data?.message) console.log(error.response.data.message);
		this.modal.show();
		this.modal.showContentBox($(".js-generic-error-modal-box-content")).enableCloseOption();
	}

}

export default Page;
