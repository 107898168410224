import axios from "axios";
import { EmployeePortalsData } from "./_portals_data";

export class EmployeeLanguage {

	static async enableMenu() {
		const employeePortalsData = new EmployeePortalsData();
		$(".js-language-menu-item").click(async function() {
			if (employeePortalsData.isAuthenticated) {
				const data = { language: $(this).data("languageSlug") };
				await axios.post(employeePortalsData.setLanguageURL, data);
			}
			window.location.href = $(this).data("currentUrl");
		});
	}

}
