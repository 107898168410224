import Page from "./_page";

export class FaqPage extends Page {

	static PAGE_CLASS = "faq-page";
	static FAQ_ITEM_SELECTOR = ".js-faq-item";
	static FAQ_ANSWER_OPEN = "closed";
	static ARROW_SELECTOR = ".js-arrow";
	static ARROW_ROTATE = "arrow-rotate";


	constructor() {
		super();
		this._applyPopup();
	}

	_applyPopup() {
		$(FaqPage.FAQ_ITEM_SELECTOR).each((index, item) => {
			$(item).click(() => {
				this._togglePopup(item);
				this._rotateArrow(item);
			});
		});
	}

	_rotateArrow($item) {
		const $arrow = $($item).find(FaqPage.ARROW_SELECTOR);
		if ($arrow.hasClass(FaqPage.ARROW_ROTATE)) {
			$arrow.removeClass(FaqPage.ARROW_ROTATE);
		} else {
			$arrow.addClass(FaqPage.ARROW_ROTATE);
		}
	}

	_togglePopup($item) {
		const $answer = $($item).next();
		if ($answer.hasClass(FaqPage.FAQ_ANSWER_OPEN)) {
			this._hide($answer);
		} else {
			this._show($answer);
		}
	}

	_hide($popup) {
		$popup.removeClass(FaqPage.FAQ_ANSWER_OPEN);
	}

	_show($popup) {
		$popup.addClass(FaqPage.FAQ_ANSWER_OPEN);
	}
}
