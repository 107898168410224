import axios from "axios";
import Modal from "./_modal";
import { HIDDEN_ELEMENT_CLASS } from "../_common";

class ReservationService {

	static COLLAPSED_ELEMENT_CLASS = "collapsed-element";
	static EMPTY_WEEK_CLASS = "empty-week";
	static EMPTY_DAY_CLASS = "empty-day";
	static EXPIRED_RESERVATION_BLOCK_CLASS = "js-expired-reservation-block";

	static OPEN_ELEMENT_ARROW_SELECTOR = ".js-arrow-open";
	static CLOSE_ELEMENT_ARROW_SELECTOR = ".js-arrow-close";
	static WEEK_SELECTOR = ".js-reservation-week";
	static WEEK_HEADER_SELECTOR = ".js-reservation-week-header";
	static DAY_SELECTOR = ".js-reservation-day";
	static BLOCK_SET_SELECTOR = ".js-reservation-block-set";
	static BLOCK_SET_HEADER_SELECTOR = ".js-reservation-block-set-header";
	static RESERVATION_BLOCK_SELECTOR = ".js-reservation-block";
	static REMOVE_RESERVATION_BLOCK_SELECTOR = ".js-remove-reservation-block";

	static REMOVE_RESERVATION_BLOCK_URL_DATA_KEY = "removeReservationBlockUrl";

	applyWeekCollapseToggleHandlers() {
		const $weekHeaders = $(ReservationService.WEEK_HEADER_SELECTOR);
		$weekHeaders.each((index, weekHeader) => {
			const $weekHeader = $(weekHeader);
			$weekHeader.click(() => {
				const $week = $weekHeader.closest(ReservationService.WEEK_SELECTOR);
				this._applyWeekCollapseToggleHandler($week);
			});
		});
	}

	_applyWeekCollapseToggleHandler($week) {
		if ($week.hasClass(ReservationService.COLLAPSED_ELEMENT_CLASS)) {
			this._expandWeek($week);
		} else {
			this._collapseWeek($week);
		}
	}

	_expandWeek($week) {
		$week.find(ReservationService.DAY_SELECTOR).removeClass(HIDDEN_ELEMENT_CLASS);
		const $weekHeader = $week.find(ReservationService.WEEK_HEADER_SELECTOR);
		$weekHeader.find(ReservationService.OPEN_ELEMENT_ARROW_SELECTOR)
			.addClass(HIDDEN_ELEMENT_CLASS);
		$weekHeader.find(ReservationService.CLOSE_ELEMENT_ARROW_SELECTOR)
			.removeClass(HIDDEN_ELEMENT_CLASS);
		$week.removeClass(ReservationService.COLLAPSED_ELEMENT_CLASS);
	}

	_collapseWeek($week) {
		$week.find(ReservationService.DAY_SELECTOR).addClass(HIDDEN_ELEMENT_CLASS);
		const $weekHeader = $week.find(ReservationService.WEEK_HEADER_SELECTOR);
		$weekHeader.find(ReservationService.CLOSE_ELEMENT_ARROW_SELECTOR)
			.addClass(HIDDEN_ELEMENT_CLASS);
		$weekHeader.find(ReservationService.OPEN_ELEMENT_ARROW_SELECTOR)
			.removeClass(HIDDEN_ELEMENT_CLASS);
		$week.addClass(ReservationService.COLLAPSED_ELEMENT_CLASS);
	}

	collapseEmptyWeeks() {
		$(ReservationService.WEEK_SELECTOR).each((index, week) => {
			const $week = $(week);
			if ($week.hasClass(ReservationService.EMPTY_WEEK_CLASS)) this._collapseWeek($week);
		});
	}

	applyBlockSetCollapseToggleHandlers() {
		const $blockSetHeaders = $(ReservationService.BLOCK_SET_HEADER_SELECTOR);
		$blockSetHeaders.each((index, blockSetHeader) => {
			const $blockSetHeader = $(blockSetHeader);
			$blockSetHeader.unbind("click");
			$blockSetHeader.click(() => {
				const $blockSet = $blockSetHeader.closest(ReservationService.BLOCK_SET_SELECTOR);
				if ($blockSet.hasClass(ReservationService.COLLAPSED_ELEMENT_CLASS)) {
					this._expandBlockSet($blockSet);
				} else {
					this._collapseBlockSet($blockSet);
				}
			});
		});
	}

	_expandBlockSet($blockSet) {
		$blockSet.find(ReservationService.RESERVATION_BLOCK_SELECTOR)
			.removeClass(HIDDEN_ELEMENT_CLASS);
		const $blockSetHeader = $blockSet.find(ReservationService.BLOCK_SET_HEADER_SELECTOR);
		$blockSetHeader.find(ReservationService.OPEN_ELEMENT_ARROW_SELECTOR)
			.addClass(HIDDEN_ELEMENT_CLASS);
		$blockSetHeader.find(ReservationService.CLOSE_ELEMENT_ARROW_SELECTOR)
			.removeClass(HIDDEN_ELEMENT_CLASS);
		$blockSet.removeClass(ReservationService.COLLAPSED_ELEMENT_CLASS);
		$blockSet.css("border-bottom", this.blockSetBorderBottom);
	}

	_collapseBlockSet($blockSet) {
		$blockSet.find(ReservationService.RESERVATION_BLOCK_SELECTOR).addClass(HIDDEN_ELEMENT_CLASS);
		const $blockSetHeader = $blockSet.find(ReservationService.BLOCK_SET_HEADER_SELECTOR);
		$blockSetHeader.find(ReservationService.CLOSE_ELEMENT_ARROW_SELECTOR)
			.addClass(HIDDEN_ELEMENT_CLASS);
		$blockSetHeader.find(ReservationService.OPEN_ELEMENT_ARROW_SELECTOR)
			.removeClass(HIDDEN_ELEMENT_CLASS);
		$blockSet.addClass(ReservationService.COLLAPSED_ELEMENT_CLASS);
		this.blockSetBorderBottom = $blockSet.css("border-bottom");
		$blockSet.css("border-bottom", "none");
	}

	applyRemoveReservationBlockHandlers() {
		$(ReservationService.RESERVATION_BLOCK_SELECTOR).each((index, reservationBlock) => {
			const $reservationBlock = $(reservationBlock);
			if (!$reservationBlock.hasClass(ReservationService.EXPIRED_RESERVATION_BLOCK_CLASS)) {
				this._applyRemoveReservationBlockHandler($reservationBlock);
			}
		});
	}

	_applyRemoveReservationBlockHandler($reservationBlock) {
		const $removeReservationBlockButton = $reservationBlock
			.find(ReservationService.REMOVE_RESERVATION_BLOCK_SELECTOR);
		const removeReservationBlockUrl = $removeReservationBlockButton
			.data(ReservationService.REMOVE_RESERVATION_BLOCK_URL_DATA_KEY);
		$removeReservationBlockButton.click(() => {
			axios.get(removeReservationBlockUrl)
				.then(() => this._removeReservationBlock($reservationBlock))
				.catch(error => this._displayErrorModal(error));
		});
	}

	_removeReservationBlock($reservationBlock) {
		const $blockSet = $reservationBlock.closest(ReservationService.BLOCK_SET_SELECTOR);
		const isLastBlockInBlockSet = $blockSet.find(ReservationService.RESERVATION_BLOCK_SELECTOR).length === 1;
		const $day = $reservationBlock.closest(ReservationService.DAY_SELECTOR);
		const isLastBlockInDay = $day.find(ReservationService.RESERVATION_BLOCK_SELECTOR).length === 1;
		$reservationBlock.remove();
		if (isLastBlockInBlockSet) $blockSet.remove();
		if (isLastBlockInDay) $day.addClass(ReservationService.EMPTY_DAY_CLASS);
	}

	_displayErrorModal(error) {
		const modal = new Modal();
		modal.show();
		if (error.response.data.message) console.log(error.response.data.message);
		modal.showContentBox("<p>Something went wrong</p>").enableCloseOption();

	}

}

export default ReservationService;
