import Page from "./_page";
import { paintSVGs } from "../../_utils";

export class TransactionsPage extends Page {

	static PAGE_CLASS = "js-user-transactions-page";

	static EXPORT_USER_TRANSACTIONS_BTN_SELECTOR = ".js-export-user-transactions-btn";
	static EXPORT_USER_TRANSACTIONS_MODAL_BOX_SELECTOR = ".js-export-user-transactions-modal-box-content";
	static EXPORT_USER_TRANSACTIONS_FORM_SELECTOR = ".js-export-user-transactions-form";
	static SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR = ".selectric .button";
	static SELECT_DROPDOWN_CARROT_SELECTOR = ".js-select-dropdown-carrot";

	constructor() {
		super();
		paintSVGs();
		this._applyExportUserTransactionButtonClickHandler();
	}

	_applyExportUserTransactionButtonClickHandler() {
		$(TransactionsPage.EXPORT_USER_TRANSACTIONS_BTN_SELECTOR).click(() => {
			const modalBoxContent = $(TransactionsPage.EXPORT_USER_TRANSACTIONS_MODAL_BOX_SELECTOR);
			this.modal.show().showContentBox(modalBoxContent).enableCloseOption();
			this._initiateSelectricField();
		});
	}

	_initiateSelectricField() {
		const $form = $(TransactionsPage.EXPORT_USER_TRANSACTIONS_FORM_SELECTOR);
		$form.find("select").selectric({
			maxHeight: 350,
			disableOnMobile: false,
			nativeOnMobile: false
		});

		const $selectDropdownCarrot = $(TransactionsPage.SELECT_DROPDOWN_CARROT_SELECTOR);
		const selectricFields = $form.find(TransactionsPage.SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR);
		selectricFields.each((index, field) => $(field).append($selectDropdownCarrot.html()));
	}

}
