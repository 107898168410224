import "../../scss/employees/index.scss";
import { Navbar } from "./_navbar";
import {
	isOnPWA,
	paintSVGs,
	prepareFlashMessages,
	prepareNestedMobileMenus,
	preparePWALoadingBar,
	setupAxios
} from "../_utils";
import $ from "jquery";
import { employeeTimezone } from "./_timezone";
import ClipboardJS from "clipboard";
import { MDCTextField } from "@material/textfield/component";
import { asyncPageClasses, regularPageClasses } from "./pages";
import { Onboarding } from "./onboarding";
import { EmployeeLanguage } from "./_language";
import { EmployeePortalsData } from "./_portals_data";
import { SentryService } from "../_sentry_service";

setupAxios();

$(window).on("load", () => $("html").addClass("loaded"));


$(document).ready(async() => {
	EmployeePortalsData.check();

	const RegularPage = regularPageClasses.find(page => page.isCurrentPage());
	RegularPage && new RegularPage();

	const AsyncPage = asyncPageClasses.find(page => page.isCurrentPage());
	!AsyncPage && await postPageInit();

	await prepareOnboardingAndInitAsyncPage(AsyncPage);

	const employeePortalsData = new EmployeePortalsData();
	employeePortalsData.isAuthenticated && await employeeTimezone.activate();
	if (employeeTimezone.shouldReloadPage) {
		window.location.reload();
	}
});

async function postPageInit() {
	Navbar.init();
	prepareNestedMobileMenus();
	paintSVGs();
	prepareFlashMessages();

	// TODO: move to specific page(s)
	// eslint-disable-next-line no-new
	new ClipboardJS(".js-copy-to-clipboard__button");

	// TODO: move to specific page(s)
	document.querySelectorAll(".js-mdc-text-field").forEach(element => {
		MDCTextField.attachTo(element);
	});

	EmployeeLanguage.enableMenu();

	isOnPWA() && preparePWALoadingBar();

	SentryService.init();
}

async function prepareOnboardingAndInitAsyncPage(AsyncPage) {
	const onboarding = new Onboarding();
	if (onboarding.shouldActivate()) {
		onboarding.activate(AsyncPage ? async() => await AsyncPage.init(postPageInit) : null);
	} else if (AsyncPage) {
		await AsyncPage.init(postPageInit);
	}
}
