import { Timezone as BaseTimezone } from "../_timezone";


class EmployeeTimezone extends BaseTimezone {

	async activate() {
		const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		await super.activate(browserTimezone);
	}

}

export const employeeTimezone = new EmployeeTimezone();
