import Page from "./_page";
import Modal from "../_modal";

export class BalancePage extends Page {

	static PAGE_CLASS = "js-user-balance-page";


	constructor() {
		super();
		this._showPaymentConfirmationModalIfPresent();
	}

	_showPaymentConfirmationModalIfPresent() {
		const modal = new Modal();
		const $modalContent = $(".js-payment-confirmation-modal-box-content");
		if ($modalContent.length) {
			modal.show().showContentBox($modalContent);
		}
	}

}
