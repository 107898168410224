import $ from "jquery";

export class SentryService {

	/**
	 * If Sentry is available in the global scope,
	 * it prepares Sentry handlers.
	 */
	static init() {
		if (typeof Sentry !== "undefined") {
			const service = new SentryService();
			service._initUserFeedbackForm();
		}
	}

	constructor() {
		this.$button = $(".js-sentry-user-feedback-button");
	}

	_initUserFeedbackForm() {
		this.$button.on("click", () => this._openUserFeedbackForm());
	}

	_openUserFeedbackForm() {
		const eventId = this.$button.data("eventId");
		const dsn = this.$button.data("userFeedbackDsn");
		// eslint-disable-next-line no-undef
		Sentry.init({ dsn });
		// eslint-disable-next-line no-undef
		Sentry.showReportDialog({ eventId });
	}

}
