import $ from "jquery";
import { HIDDEN_ELEMENT_CLASS } from "./_common";
import axios from "axios";
import { FormService } from "./_form_service";

export class LicensePlatesFormService extends FormService {

	static ADD_LICENSE_PLATE_BUTTON_SELECTOR = ".js-add-license-plate";
	static DELETE_LICENSE_PLATE_BUTTON_SELECTOR = ".js-delete-license-plate-btn";

	static REQUIRED_FORM_FIELD_CSS_CLASS = "required-field";

	static LICENSE_PLATE_FIELDS_COUNT_DATA_KEY = "licensePlateFieldsCount";

	constructor() {
		super(".js-license-plates-form");
		this.allowsEmptyForm = false;
		this.$form.on("submit", async event => {
			event.preventDefault();
			await this.submit();
		});
		this.$addLicensePlateButton = this.$form.find(LicensePlatesFormService.ADD_LICENSE_PLATE_BUTTON_SELECTOR);
		this.$addLicensePlateButton.on("click", () => this._createNewLicensePlateField());
		this._handleAddLicensePlateButtonVisibility();
		this._prepareDeleteLicensePlateButtons();
		this._prepareFormFields();
	}

	allowEmptyForm() {
		this.allowsEmptyForm = true;
		this._prepareDeleteLicensePlateButtons();
		this._prepareFormFields();
	}

	_handleAddLicensePlateButtonVisibility() {
		if (this._shouldHideAddLicensePlateButton()) {
			this.$addLicensePlateButton.addClass(HIDDEN_ELEMENT_CLASS);
		} else {
			this.$addLicensePlateButton.removeClass(HIDDEN_ELEMENT_CLASS);
		}
	}

	_shouldHideAddLicensePlateButton() {
		let hasEmptyFields = false;
		this.$form.find(`${LicensePlatesFormService.FORM_FIELD_SELECTOR} input`).each((_, element) => {
			if (!$(element).val()) {
				hasEmptyFields = true;
				return false;
			}
		});
		return hasEmptyFields;
	}

	_prepareDeleteLicensePlateButtons() {
		const $deleteButtons = this.$form.find(LicensePlatesFormService.DELETE_LICENSE_PLATE_BUTTON_SELECTOR);
		$deleteButtons.each((index, element) => {
			if (!$._data(element, "events")?.click) {
				$(element).on("click", event => this._deleteLicensePlate(event));
			}
		});

		if ($deleteButtons.length > 1 || $deleteButtons.data("url") || this.allowsEmptyForm) {
			$deleteButtons.removeClass(HIDDEN_ELEMENT_CLASS);
		} else {
			$deleteButtons.addClass(HIDDEN_ELEMENT_CLASS);
		}
	}

	_prepareFormFields() {
		const $addLicensePlateButton = this.$form.find(LicensePlatesFormService.ADD_LICENSE_PLATE_BUTTON_SELECTOR);
		const self = this;
		this.$form.find(LicensePlatesFormService.FORM_FIELD_SELECTOR).each((index, element) => {
			if (!index) {
				const $labelText = $(element).find("label .label-text");
				if (self.allowsEmptyForm) {
					$labelText.removeClass(LicensePlatesFormService.REQUIRED_FORM_FIELD_CSS_CLASS);
				} else {
					$labelText.addClass(LicensePlatesFormService.REQUIRED_FORM_FIELD_CSS_CLASS);
				}
			}
			if (!$._data(element, "events")?.input) {
				$(element).on("input", () => {
					if (this._shouldHideAddLicensePlateButton()) {
						$addLicensePlateButton.addClass(HIDDEN_ELEMENT_CLASS);
					} else {
						$addLicensePlateButton.removeClass(HIDDEN_ELEMENT_CLASS);
					}
				});
			}
		});
	}

	_createNewLicensePlateField() {
		const licensePlateFieldsCount = this.$form.data(LicensePlatesFormService.LICENSE_PLATE_FIELDS_COUNT_DATA_KEY);
		const newLicensePlateFieldNumber = licensePlateFieldsCount + 1;
		const newLicensePlateInputID = `new-license-plate-${newLicensePlateFieldNumber}`;

		const $newLicensePlateField = this.$form.find(LicensePlatesFormService.FORM_FIELD_SELECTOR).last().clone();
		$newLicensePlateField.removeClass(LicensePlatesFormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
		$newLicensePlateField.find(`.${LicensePlatesFormService.FORM_FIELD_ERROR_CSS_CLASS}`).remove();
		$newLicensePlateField.find("label").attr("for", newLicensePlateInputID);
		$newLicensePlateField.find(".required-field")
			.removeClass(LicensePlatesFormService.REQUIRED_FORM_FIELD_CSS_CLASS);

		const $deleteButton = $newLicensePlateField.find(LicensePlatesFormService.DELETE_LICENSE_PLATE_BUTTON_SELECTOR);
		$deleteButton.data("url", "");

		$deleteButton.attr("id", `delete-license-plate-btn-${newLicensePlateFieldNumber}`);

		const $inputElement = $newLicensePlateField.find("input");
		$inputElement.attr({
			id: newLicensePlateInputID,
			name: `new_license_plate_${newLicensePlateFieldNumber}`,
			disabled: false
		});
		$inputElement.val("");

		this.$form.find(LicensePlatesFormService.FORM_FIELDS_CONTAINER_SELECTOR).append($newLicensePlateField);
		this.$form.data(LicensePlatesFormService.LICENSE_PLATE_FIELDS_COUNT_DATA_KEY, newLicensePlateFieldNumber);

		this._handleAddLicensePlateButtonVisibility();
		this._prepareDeleteLicensePlateButtons();
		this._prepareFormFields();
	}

	async _deleteLicensePlate(event) {
		const $deleteButton = this._getDeleteLicensePlateButton(event);
		const deleteLicensePlateURL = $deleteButton.data("url");
		if (deleteLicensePlateURL) {
			try {
				await axios.delete(deleteLicensePlateURL);
			} catch (error) {
				/**
				 * For some reason, non-local environments return error response (502),
				 * but the license plate is deleted successfully
				 */
				console.log(error);
			}
		}
		this._removeLicensePlateField($deleteButton);
		this._prepareFormFields();
		this._prepareDeleteLicensePlateButtons();
		this._handleAddLicensePlateButtonVisibility();
		this._clearErrors();
	}

	_getDeleteLicensePlateButton(event) {
		const $target = $(event.target);
		if ($target.hasClass(LicensePlatesFormService.DELETE_LICENSE_PLATE_BUTTON_SELECTOR)) {
			return $target;
		}
		return $target.closest(LicensePlatesFormService.DELETE_LICENSE_PLATE_BUTTON_SELECTOR);
	}

	_removeLicensePlateField($deleteButton) {
		const licensePlateFieldsCount = this.$form.find(LicensePlatesFormService.FORM_FIELD_SELECTOR).length;
		if (licensePlateFieldsCount > 1) {
			$deleteButton.closest(LicensePlatesFormService.FORM_FIELD_SELECTOR).remove();
			this.$form.data(LicensePlatesFormService.LICENSE_PLATE_FIELDS_COUNT_DATA_KEY, licensePlateFieldsCount - 1);
		} else {
			$deleteButton.addClass(HIDDEN_ELEMENT_CLASS);
			const $licensePlateField = $deleteButton.closest(LicensePlatesFormService.FORM_FIELD_SELECTOR);
			const $inputElement = $licensePlateField.find("input");
			$inputElement.attr({
				disabled: false,
				name: "new_license_plate_1",
				id: "new-license-plate-1"
			});
			$inputElement.val("");
		}
	}

}
