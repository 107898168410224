import $ from "jquery";
import { HIDDEN_ELEMENT_CLASS, MAX_MOBILE_WIDTH_IN_PIXELS } from "./_common";
import axios from "axios";

export function getFormData($form) {
	return $form.serializeArray().reduce((a, b) => ({ ...a, [b.name]: b.value }), {});
}

export function incrementDateByHours(date, hours) {
	const dateCopy = new Date(date.getTime());
	dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
	return dateCopy;
}

export function paintSVGs() {
	$(".js-paintable-svg").each((index, element) => {
		const $svgParent = $(element);
		const svgColor = $svgParent.css("color");
		if (svgColor) {
			$svgParent.find("svg path").attr("fill", svgColor);
			$svgParent.find("svg rect").attr("fill", svgColor);
		}
	});
}

export function prepareNestedMobileMenus() {
	$(".js-nested-mobile-menu").each((index, element) => {
		const $nestedMenu = $(element);
		const $nestedMenuItems = $($nestedMenu.find(".js-nested-mobile-menu__items").get(0));

		const $openButton = $($nestedMenu.find(".js-nested-mobile-menu__open").get(0));
		$openButton.click(() => $nestedMenuItems.removeClass(HIDDEN_ELEMENT_CLASS));

		const $closeButton = $($nestedMenu.find(".js-nested-mobile-menu__close").get(0));
		$closeButton.click(() => $nestedMenuItems.addClass(HIDDEN_ELEMENT_CLASS));
	});
}

export function isOnMobile() {
	return window.innerWidth < MAX_MOBILE_WIDTH_IN_PIXELS;
}

export function prepareFlashMessages() {
	$(".js-flash-message-close-button").on("click", function() {
		$(this).parents(".js-flash-message").addClass(HIDDEN_ELEMENT_CLASS);
	});
}

export function isElementInViewport(element) {
	const rect = element.getBoundingClientRect();
	return rect.top >= 0 && rect.left >= 0 && rect.right <= window.innerWidth && rect.bottom <= window.innerHeight;
}

export function setupAxios() {
	axios.defaults.xsrfCookieName = "csrftoken";
	axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
	axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
}

export function isOnPWA() {
	return window.matchMedia("(display-mode: standalone)").matches;
}

export function preparePWALoadingBar() {
	$(window).on("pageshow", function() {
		$(".js-pwa-loading-bar").removeClass("active");
	});
	$("a, form button[type='submit']").click(function() {
		$(".js-pwa-loading-bar").addClass("active");
	});
}

/**
 * Compares two dates and returns true if they are the same.
 * It does not compare the time of the dates.
 */
export function areSameDates(firstDate, secondDate) {
	const year1 = firstDate.getFullYear();
	const month1 = firstDate.getMonth();
	const day1 = firstDate.getDate();

	const year2 = secondDate.getFullYear();
	const month2 = secondDate.getMonth();
	const day2 = secondDate.getDate();

	return year1 === year2 && month1 === month2 && day1 === day2;
}
