import { isOnMobile } from "../_utils";

export class Navbar {

	static BURGER_BTN_SELECTOR = ".js-navbar-burger";
	static MAIN_MENU_SELECTOR = ".js-burger-menu";

	static AVATAR_ICON_SELECTOR = ".js-navbar-avatar";
	static USER_MENU_SELECTOR = ".js-user-menu";

	static IS_OPENED_MENU_CLASS = "opened";

	static init() {
		const navbar = new Navbar();
		$(window).on("pageshow", function() {
			navbar._hideOpenedMenus();
		});
		navbar._applyNavbarHandlers();
	}

	_applyNavbarHandlers() {
		$(Navbar.BURGER_BTN_SELECTOR).click(() => {
			if (isOnMobile()) {
				const $mainMenu = $(Navbar.MAIN_MENU_SELECTOR);
				this._toggleMenuVisibility($mainMenu);
			}
		});
		$(Navbar.AVATAR_ICON_SELECTOR).click(() => {
			const $userMenu = $(Navbar.USER_MENU_SELECTOR);
			this._toggleMenuVisibility($userMenu);
		});
	}

	_toggleMenuVisibility($menu) {
		const shouldOpen = !$menu.hasClass(Navbar.IS_OPENED_MENU_CLASS);
		this._hideOpenedMenus();
		if (shouldOpen) {
			$menu.addClass(Navbar.IS_OPENED_MENU_CLASS);
			$("main, footer").hide();
		}
	}

	_hideOpenedMenus() {
		const $openedMenus = $(`.${Navbar.IS_OPENED_MENU_CLASS}`);
		$openedMenus.removeClass(Navbar.IS_OPENED_MENU_CLASS);
		$("main, footer").show();
	}

}
