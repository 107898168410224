import axios from "axios";
import ReservationService from "../_reservation_service";
import { paintSVGs } from "../../_utils";
import Page from "./_page";

export class ReservationHistoryPage extends Page {

	static PAGE_CLASS = "js-reservation-history-page";

	constructor() {
		super();
		this.$reservationHistoryContentContainer = $(".js-reservation-history-content");
		this.$showMoreHistoryButton = $(".js-show-more-history-btn");
		this.pageNumber = 1;
	}

	static async init(callback) {
		const reservationHistoryPage = new ReservationHistoryPage();
		await reservationHistoryPage._loadContent();
		typeof callback === "function" && await callback();
	}

	async _loadContent() {
		const previousWindowScrollY = window.scrollY;
		this.modal.show().showSpinner();
		const baseUrl = this.$reservationHistoryContentContainer.data("reservationHistoryUrl");

		try {
			const response = await axios.get(`${baseUrl}?page=${this.pageNumber++}`);
			this._renderResponseData(response);
			return true;
		} catch (error) {
			this.$reservationHistoryContentContainer.html("<p>Error</p>");
			return false;
		} finally {
			this._postLoadContent(previousWindowScrollY);
		}
	}

	_renderResponseData(response) {
		this.$reservationHistoryContentContainer.append(response.data.template);
		const reservationService = new ReservationService();
		reservationService.applyBlockSetCollapseToggleHandlers();
		paintSVGs();
	}

	_postLoadContent(windowScrollY) {
		if (this._hasReachedEndOfHistory()) {
			this.$showMoreHistoryButton.css("visibility", "hidden");
		} else {
			this.$showMoreHistoryButton.click(() => this._loadContent());
			this.$showMoreHistoryButton.css("visibility", "visible");
		}
		this.modal.hide();
		$("html,body").scrollTop(windowScrollY);
	}

	_hasReachedEndOfHistory() {
		return !!this.$reservationHistoryContentContainer.find(".js-end-of-history").length;
	}
}
