import { getFormData, isOnMobile, isOnPWA } from "../../_utils";
import $ from "jquery";
import Modal from "../_modal";
import { OnboardingSteps } from "./_onboarding_steps";
import axios from "axios";
import { EmployeePortalsData } from "../_portals_data";


export class Onboarding {

	constructor() {
		this.employeePortalsData = new EmployeePortalsData();
		const $openOnboardingButton = $(".js-open-onboarding-button");
		if (this._isAvailable()) {
			$openOnboardingButton.click(() => this.activate());
		} else {
			$openOnboardingButton.css("display", "none");
		}
	}

	shouldActivate() {
		return !this.employeePortalsData.isOnboarded && this._isAvailable();
	}

	_isAvailable() {
		return (
			this.employeePortalsData.isAuthenticated
			&& isOnMobile()
			&& !!$(".js-onboarding-modal-box-content").length
			&& !isOnPWA()
		);
	}

	activate(onCompleted) {
		this._activateModal();
		this._prepareForm(onCompleted);
		this._enableSteps();
	}

	_activateModal() {
		new Modal()
			.show()
			.enableCloseOption()
			.enableMobileFullScreen()
			.showContentBox($(".js-onboarding-modal-box-content"));
	}

	_prepareForm(onCompleted) {
		const self = this;
		const $form = $(".js-complete-onboarding-form");
		$form.submit(async function(event) {
			event.preventDefault();
			self.employeePortalsData.setIsOnboarded(true);
			typeof onCompleted === "function" && await onCompleted();
			await axios.post($form.attr("action"), getFormData($form));
		});
		$(".js-complete-onboarding").click(function() {
			$form.submit();
		});
	}

	_enableSteps() {
		const onboardingSteps = new OnboardingSteps($(Modal.MODAL_BOX_CONTENT_SELECTOR));
		onboardingSteps.openStep(1);
	}
}
