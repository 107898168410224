import Page from "./_page";
import PaymentFormService from "../../_payment_form_service";
import { paintSVGs } from "../../_utils";

export class PaymentPage extends Page {

	static PAGE_CLASS = "js-payment-page";

	static PAYMENT_FORM_SELECTOR = ".js-payment-from";
	static SELECT_DROPDOWN_CARROT_SELECTOR = ".js-select-dropdown-carrot";
	static SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR = ".selectric .button";

	constructor() {
		super();
		this.paymentFormService = new PaymentFormService();
		this.paymentFormService.initEventHandlers();

		// Paint svg images hidden in html that should be painted
		// before they are being used in select fields initialization:
		paintSVGs();

		this._initSelectFields();
	}

	_initSelectFields() {
		const $reservationForm = $(PaymentPage.PAYMENT_FORM_SELECTOR);
		$reservationForm.find("select").selectric({
			maxHeight: 350,
			disableOnMobile: false,
			nativeOnMobile: false,
			onChange: () => {
				this.paymentFormService.toggleIdealPaymentIssuersField();
			}
		});

		const $selectDropdownCarrot = $(PaymentPage.SELECT_DROPDOWN_CARROT_SELECTOR);
		const selectricFields = $reservationForm.find(PaymentPage.SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR);
		selectricFields.each((index, field) => $(field).append($selectDropdownCarrot.html()));
	};

}
