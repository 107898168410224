import Page from "./_page";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";

export class TwoFALoginPage extends Page {

	static PAGE_CLASS = "js-two-fa-login-page";

	static TWO_FA_SETUP_BUTTON_SELECTOR = ".js-two-fa-setup-button";
	static TWO_FA_RESET_BUTTON_SELECTOR = ".js-two-fa-reset-button";

	static TWO_FA_INSTRUCTIONS_MODAL_BOX_SELECTOR = ".js-two-fa-instructions-modal-box-content";
	static TWO_FA_RESET_MODAL_BOX_SELECTOR = ".js-two-fa-reset-modal-box-content";

	static COPY_TO_CLIPBOARD_SELECTOR = ".js-copy-to-clipboard";
	static COPY_TO_CLIPBOARD_BUTTON_SELECTOR = ".js-copy-to-clipboard__button";
	static COPY_TO_CLIPBOARD_FIELD_SELECTOR = ".js-copy-to-clipboard__field";
	static COPY_TO_CLIPBOARD_EXECUTED_INFO_SELECTOR = ".js-copy-to-clipboard__action-executed-info";

	static COPIED_FIELD_CLASS = "two-fa-copied-field";
	static TWO_FA_RESET_MODAL_BOX_CLASS = "modal__box--two-fa-reset";

	constructor() {
		super();
		this._preparePageButtons();
	}

	_preparePageButtons() {
		$(TwoFALoginPage.TWO_FA_SETUP_BUTTON_SELECTOR).click(() => {
			const modalBoxContent = $(TwoFALoginPage.TWO_FA_INSTRUCTIONS_MODAL_BOX_SELECTOR);
			this.modal.show().showContentBox(modalBoxContent).enableCloseOption();
			this._prepareCopyToClipboardElements();
		});
		$(TwoFALoginPage.TWO_FA_RESET_BUTTON_SELECTOR).click(() => {
			const modalBoxContent = $(TwoFALoginPage.TWO_FA_RESET_MODAL_BOX_SELECTOR);
			this.modal.show().showContentBox(modalBoxContent).enableCloseOption();
			this.modal.$modalBox.addClass(TwoFALoginPage.TWO_FA_RESET_MODAL_BOX_CLASS);
			this._prepareCopyToClipboardElements();
		});
	}

	_prepareCopyToClipboardElements() {
		$(TwoFALoginPage.COPY_TO_CLIPBOARD_SELECTOR)
			.find(TwoFALoginPage.COPY_TO_CLIPBOARD_BUTTON_SELECTOR)
			.click(function() {
				const $copiedField = $(this).closest(TwoFALoginPage.COPY_TO_CLIPBOARD_FIELD_SELECTOR);
				const $copyExecutedInfo = $(this)
					.closest(TwoFALoginPage.COPY_TO_CLIPBOARD_SELECTOR)
					.find(TwoFALoginPage.COPY_TO_CLIPBOARD_EXECUTED_INFO_SELECTOR);

				$copyExecutedInfo.removeClass(HIDDEN_ELEMENT_CLASS);
				$copiedField.addClass(TwoFALoginPage.COPIED_FIELD_CLASS);

				setTimeout(() => {
					$copyExecutedInfo.fadeOut("slow", () => {
						$copyExecutedInfo.addClass(HIDDEN_ELEMENT_CLASS);
						$copyExecutedInfo.removeAttr("style");
					});
					$copiedField.removeClass(TwoFALoginPage.COPIED_FIELD_CLASS);
				}, 3000);
			});
	}

}
