import $ from "jquery";
import { LicensePlatesFormService as BaseLicensePlatesFormService } from "../_license_plates_form_service";
import Modal from "./_modal";

export class LicensePlatesFormService extends BaseLicensePlatesFormService {

	static LICENSE_PLATE_FIELD_LABEL_NUMBER_SELECTOR = ".js-license-plate-field-label-number";

	constructor() {
		super();
		this.modal = new Modal();
	}

	async submit() {
		this.modal.show().showSpinner();
		await super.submit();
		if (this.hasErrors()) {
			this.modal.hide();
		} else {
			window.location.reload();
		}
	}

	async _deleteLicensePlate(event) {
		this.modal.show().showSpinner();
		await super._deleteLicensePlate(event);
		$(LicensePlatesFormService.FORM_FIELD_SELECTOR).each((index, element) => {
			$(element).find(LicensePlatesFormService.LICENSE_PLATE_FIELD_LABEL_NUMBER_SELECTOR).html(index + 1);
		});
		this.modal.hide();
	}

	_createNewLicensePlateField() {
		super._createNewLicensePlateField();
		$(LicensePlatesFormService.FORM_FIELD_SELECTOR)
			.last()
			.find(LicensePlatesFormService.LICENSE_PLATE_FIELD_LABEL_NUMBER_SELECTOR)
			.html(this.$form.data(LicensePlatesFormService.LICENSE_PLATE_FIELDS_COUNT_DATA_KEY));
	}

}
