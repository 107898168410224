import $ from "jquery";

class PaymentFormService {

	static PAYMENT_TYPE_FIELD_SELECTOR = ".js-payment-type-field";
	static IDEAL_PAYMENT_ISSUER_FIELD_SELECTOR = ".js-ideal-payment-issuer-field";

	constructor() {
		this.$paymentTypesField = $(PaymentFormService.PAYMENT_TYPE_FIELD_SELECTOR);
		this.$idealPaymentIssuersField = $(PaymentFormService.IDEAL_PAYMENT_ISSUER_FIELD_SELECTOR);
	}

	initEventHandlers() {
		if (this.$idealPaymentIssuersField.length) {
			this.toggleIdealPaymentIssuersField();
			this._applyEventHandlersToPaymentTypeChoices();
		}
	}

	toggleIdealPaymentIssuersField() {
		if (this._hasChosenIDealPaymentType()) {
			this.$idealPaymentIssuersField.show();
		} else {
			this.$idealPaymentIssuersField.hide();
		}
	}

	_hasChosenIDealPaymentType() {
		const iDEALPaymentChoice = this._getIDEALPaymentChoice();
		return iDEALPaymentChoice.is(":selected");
	}

	_applyEventHandlersToPaymentTypeChoices = () => {
		const iDEALPaymentChoice = this._getIDEALPaymentChoice();
		iDEALPaymentChoice.on("click", () => this.$idealPaymentIssuersField.show());
		this.$paymentTypesField.on("change", () => this.toggleIdealPaymentIssuersField());

		const nonIDEALPaymentChoices = this._getNonIDEALPaymentTypeChoices();
		nonIDEALPaymentChoices.forEach(choice => choice.click(() => this.$idealPaymentIssuersField.hide()));
	};

	_getIDEALPaymentChoice = () => {
		const $paymentTypeChoices = this._getPaymentTypeChoices();
		return $paymentTypeChoices.find(choice => choice.val() === "ideal");
	};

	_getNonIDEALPaymentTypeChoices = () => {
		const $paymentTypeChoices = this._getPaymentTypeChoices();
		return $paymentTypeChoices.filter(choice => choice.val() !== "ideal");
	};

	_getPaymentTypeChoices = () => {
		const paymentTypeChoices = this.$paymentTypesField.find("option").toArray();
		return paymentTypeChoices.map((inputElement) => $(inputElement));
	};

}

export default PaymentFormService;
