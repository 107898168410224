import $ from "jquery";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";
import { MobileService } from "../../_mobile_os_service";
import ClipboardJS from "clipboard";

export class OnboardingSteps {
	constructor($modalContent) {
		this.steps = [1, 2];
		this.$modalContent = $modalContent;
		this._prepareStepButtons();
		this._prepareOSSpecificSecondStep();
	}

	_prepareStepButtons() {
		this.steps.forEach(step => {
			this.$modalContent.find(`.js-open-onboarding-step-${step}`).click(() => {
				this.openStep(step);
			});
		});
	}

	_prepareOSSpecificSecondStep() {
		const $onboardingStep2Content = this._getOSSpecificSecondStepContent();
		if ($onboardingStep2Content) {
			$onboardingStep2Content.removeClass(HIDDEN_ELEMENT_CLASS);
		}

		const $copyToClipboardContainer = $(".js-copy-to-clipboard");
		if ($copyToClipboardContainer.length) {
			this._prepareCopyToClipboardContainer($copyToClipboardContainer);
		}
	}

	_prepareCopyToClipboardContainer($copyToClipboardContainer) {
		// eslint-disable-next-line no-new
		new ClipboardJS(".js-copy-to-clipboard__button");

		$copyToClipboardContainer.find(".js-copy-to-clipboard__button").click(function() {
			const $copyToClipboardConfirmation = $copyToClipboardContainer.find(".js-copy-to-clipboard__confirmation");
			$copyToClipboardConfirmation.removeClass(HIDDEN_ELEMENT_CLASS);
			setTimeout(function() {
				$copyToClipboardConfirmation.fadeOut("slow", () => {
					$copyToClipboardConfirmation.addClass(HIDDEN_ELEMENT_CLASS);
					$copyToClipboardConfirmation.removeAttr("style");
				});
			}, 3000);
		});
	}

	_getOSSpecificSecondStepContent() {
		const $onboardingStep2 = $(".js-onboarding-step-2");
		if (MobileService.isIOS()) {
			if (MobileService.isSafariOnIOS()) {
				return $onboardingStep2.find(".js-onboarding-step-2__ios--safari");
			}
			return $onboardingStep2.find(".js-onboarding-step-2__ios--no-safari");
		}
		if (MobileService.isAndroid()) {
			return $onboardingStep2.find(".js-onboarding-step-2__android");
		}
	}

	openStep(stepToOpen) {
		this.steps.forEach(step => {
			const $stepContent = $(`.js-onboarding-step-${step}`);
			if (step === stepToOpen) {
				$stepContent.removeClass(HIDDEN_ELEMENT_CLASS);
			} else {
				$stepContent.addClass(HIDDEN_ELEMENT_CLASS);
			}
		});
	}

}
