import axios from "axios";
import ReservationService from "../_reservation_service";
import { paintSVGs } from "../../_utils";
import { HIDDEN_ELEMENT_CLASS, MAX_MOBILE_WIDTH_IN_PIXELS } from "../../_common";
import Page from "./_page";

export class ReservationPage extends Page {

	static PAGE_CLASS = "js-reservation-page";

	static PAGE_CONTENT_CONTAINER_SELECTOR = ".js-reservation-page-content";
	static WEEK_SLIDER_SELECTOR = ".js-week-slider";
	static WEEK_TAB_SELECTOR = ".js-week-tab";
	static WEEK_TAB_SELECTED_SELECTOR = ".js-week-tab-selected";
	static WEEK_ARROW_PREV_SELECTOR = ".js-week-slider-arrow-prev";
	static WEEK_ARROW_NEXT_SELECTOR = ".js-week-slider-arrow-next";
	static RESERVATION_WEEK_SELECTOR = ".js-reservation-week";
	static RESERVATION_BLOCK_ACTION_SELECTOR = ".js-reservation-block-action";
	static LOADING_RESERVATION_BLOCKS_SPINNER_MSG_SELECTOR = ".js-loading-reservation-blocks-spinner-message";
	static EXECUTING_RESERVATION_ACTION_SPINNER_MSG_SELECTOR = ".js-executing-reservation-actions-spinner-message";

	static WEEK_TAB_URL_DATE_KEY = "weekTabUrl";
	static WEEK_TAB_INDEX_DATA_KEY = "weekTabIndex";
	static RESERVATION_OPTIONS_URL_DATA_KEY = "reservationOptionsUrl";
	static REFRESH_RESERVATION_OPTIONS_URL_DATA_KEY = "refreshReservationOptionsUrl";
	static RESERVATION_BLOCK_ACTION_URL_DATA_KEY = "reservationBlockActionUrl";

	static LAST_WEEK_TAB_CLASS = "js-last-week-tab";

	static async init(callback) {
		const reservationPage = new ReservationPage();
		const hasLoadedContent = await reservationPage._loadContent();
		if (hasLoadedContent) {
			const reservationService = new ReservationService();
			reservationService.applyBlockSetCollapseToggleHandlers();
			reservationPage._initContentWatcher();
		}
		typeof callback === "function" && await callback();
	}

	constructor() {
		super();
		this.$pageContentContainer = $(ReservationPage.PAGE_CONTENT_CONTAINER_SELECTOR);
		this.currentContentURL = this.$pageContentContainer.data(ReservationPage.RESERVATION_OPTIONS_URL_DATA_KEY);
		this.isOnFirstWeek = false;
		this.isOnLastWeek = false;
		this.contentLoadedAt = null;
		this.isLoadingContent = false;
	}

	/**
	 * Checks (in regular intervals) if the content is
	 * fresh enough and loads fresh content if it isn't
	 */
	_initContentWatcher() {
		setInterval(async() => {
			if (this._shouldLoadFreshContent()) {
				await this._loadContent();
			}
		}, 2000);
	}

	_shouldLoadFreshContent() {
		if (document.hidden || this.isLoadingContent) {
			return false;
		}
		const secondsSinceLastContent = (new Date() - this.contentLoadedAt) / 1000;
		const freshContentIntervalSeconds = this.$pageContentContainer.data("freshContentIntervalSeconds");
		return secondsSinceLastContent > freshContentIntervalSeconds;
	}

	async _loadContent(url) {
		this.isLoadingContent = true;
		if (!this.modal.isActive()) {
			const spinnerMessage = $(ReservationPage.LOADING_RESERVATION_BLOCKS_SPINNER_MSG_SELECTOR).html();
			this.modal.show().showSpinner(spinnerMessage);
		}
		try {
			this.currentContentURL = url || this.currentContentURL;
			const response = await axios.get(this.currentContentURL);
			this._renderPageContent(response.data.template);
			this._prepareWeekSlider();
			this._prepareReservationBlocks();
			paintSVGs();
			return true;
		} catch (error) {
			if (error.response.data.message) console.error(`Internal server error: ${error.response.data.message}`);
			this.$pageContentContainer.html("<p>Internal server error</p>");
			return false;
		} finally {
			this.isLoadingContent = false;
			this.modal.hide();
			this.contentLoadedAt = new Date();
		}
	}

	_renderPageContent(content) {
		this.$pageContentContainer.html(content);
	}

	_prepareWeekSlider() {
		this._initializeWeekSlider();
		$(ReservationPage.WEEK_TAB_SELECTOR).each((_, weekTab) => {
			$(weekTab).on("click", async() => await this._loadWeekContentIfAvailable($(weekTab)));
		});
	}

	async _loadWeekContentIfAvailable($weekTab) {
		const isUnavailableWeek = $weekTab.hasClass("js-week-tab-unavailable");
		const isEmptyWeek = $weekTab.hasClass("js-week-tab-empty");
		if (!(isUnavailableWeek || isEmptyWeek)) {
			const url = $weekTab.data(ReservationPage.WEEK_TAB_URL_DATE_KEY);
			await this._loadContent(url);
		}
	}

	_initializeWeekSlider() {
		if (this.isOnMobile()) {
			$(".js-week-tab-empty").parent().remove();
			$(ReservationPage.WEEK_TAB_SELECTOR).last().addClass(ReservationPage.LAST_WEEK_TAB_CLASS);
		}

		const initialWeekTabIndex = this._getInitialWeekTab();
		$(ReservationPage.WEEK_SLIDER_SELECTOR).slick({
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			prevArrow: $(ReservationPage.WEEK_ARROW_PREV_SELECTOR),
			nextArrow: $(ReservationPage.WEEK_ARROW_NEXT_SELECTOR),
			initialSlide: initialWeekTabIndex,
			responsive: [
				{
					breakpoint: MAX_MOBILE_WIDTH_IN_PIXELS + 50,
					settings: {
						slidesToShow: 1,
						initialSlide: this._getSelectedWeekTabIndex()
					}
				}
			]
		});

		const lastWeekTabIndex = $(ReservationPage.WEEK_TAB_SELECTOR).length - 1;

		if (this.isOnMobile()) {
			$(ReservationPage.WEEK_ARROW_PREV_SELECTOR).css("width", "17.5%");
			$(ReservationPage.WEEK_ARROW_NEXT_SELECTOR).css("width", "17.5%");
			$(".slick-track").css("width", "100%");
			$(".slick-slide").css("width", "100%");
		}

		$(ReservationPage.WEEK_SLIDER_SELECTOR).on("beforeChange", (event, slick, currentSlide, nextSlide) => {
			const $weekTab = $(`.js-week-tab-index-${nextSlide}`);
			const isUnavailableWeek = $weekTab.hasClass("js-week-tab-unavailable");
			if (this.isOnMobile() && isUnavailableWeek) {
				$(".js-reservation-week").remove();
			}
		});

		$(ReservationPage.WEEK_SLIDER_SELECTOR).on("afterChange", async(event, slick, currentSlide) => {
			paintSVGs();
			const shouldLoadWeekContent = !(
				(this.isOnFirstWeek && currentSlide === 0)
				|| (this.isOnLastWeek && currentSlide === lastWeekTabIndex)
			);
			if (this.isOnMobile() && shouldLoadWeekContent) {
				const $weekTab = $(`.js-week-tab-index-${currentSlide}`);
				await this._loadWeekContentIfAvailable($weekTab);
			}
			this.isOnLastWeek = currentSlide === lastWeekTabIndex;
			this.isOnFirstWeek = currentSlide === 0;
		});
	}

	_getInitialWeekTab() {
		const $selectedWeekTab = $(ReservationPage.WEEK_TAB_SELECTED_SELECTOR);
		const selectedWeekTabIndex = $selectedWeekTab.data(ReservationPage.WEEK_TAB_INDEX_DATA_KEY);
		const isLastWeekTab = $selectedWeekTab.hasClass(ReservationPage.LAST_WEEK_TAB_CLASS);
		if (isLastWeekTab) return selectedWeekTabIndex - 2;
		if (selectedWeekTabIndex <= 1) return 0;
		return selectedWeekTabIndex - 1;
	}

	_getSelectedWeekTabIndex() {
		const $selectedWeekTab = $(ReservationPage.WEEK_TAB_SELECTED_SELECTOR);
		return $selectedWeekTab.data(ReservationPage.WEEK_TAB_INDEX_DATA_KEY);
	}

	_prepareReservationBlocks() {
		const refreshPageURL = $(ReservationPage.RESERVATION_WEEK_SELECTOR)
			.data(ReservationPage.REFRESH_RESERVATION_OPTIONS_URL_DATA_KEY);
		this._prepareReservationBlockActionArea(refreshPageURL);
	}

	_prepareReservationBlockActionArea(refreshPageURL) {
		$(ReservationPage.RESERVATION_BLOCK_ACTION_SELECTOR).each((index, blockActionArea) => {
			const $blockActionArea = $(blockActionArea);
			$blockActionArea.click((event) => {
				event.stopPropagation();
				this._handleReservationBlockAction($blockActionArea, refreshPageURL);
			});
		});
	}

	_handleReservationBlockAction($blockActionArea, successURL) {
		const previousWindowScrollY = window.scrollY;
		this._showReservationBlockActionSpinner($blockActionArea);
		const blockActionURL = $blockActionArea.data(ReservationPage.RESERVATION_BLOCK_ACTION_URL_DATA_KEY);
		axios.get(blockActionURL)
			.then(async() => {
				await this._loadContent(successURL);
				$("html,body").scrollTop(previousWindowScrollY);
			})
			.catch(error => this.handleAjaxRequestError(error));
	}

	_showReservationBlockActionSpinner($blockActionArea) {
		const isCancelingReservation = $blockActionArea.hasClass("js-cancelable-reservation-block");
		const actionTextSelector = isCancelingReservation
			? ".js-canceling-reservation"
			: ".js-creating-reservation";
		const $spinnerMessage = $(ReservationPage.EXECUTING_RESERVATION_ACTION_SPINNER_MSG_SELECTOR).clone();
		$spinnerMessage.find(actionTextSelector).removeClass(HIDDEN_ELEMENT_CLASS);

		this.modal.show();
		if (isCancelingReservation) {
			this.modal.showRedSpinner($spinnerMessage.html());
		} else {
			this.modal.showSpinner($spinnerMessage.html());
		}
	}

}
