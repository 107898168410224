import $ from "jquery";

export class EmployeePortalsData {

	static SET_LANGUAGE_URL_KEY = "setLanguageUrl";
	static ACTIVATE_TIMEZONE_URL_KEY = "activateTimezoneUrl";
	static IS_AUTHENTICATED_KEY = "isAuthenticated";
	static IS_ONBOARDED_KEY = "isOnboarded";

	static REQUIRED_DATA_KEYS = [
		EmployeePortalsData.SET_LANGUAGE_URL_KEY,
		EmployeePortalsData.ACTIVATE_TIMEZONE_URL_KEY,
		EmployeePortalsData.IS_AUTHENTICATED_KEY,
		EmployeePortalsData.IS_ONBOARDED_KEY
	];

	static check() {
		const portalsDataElement = this._getElement();
		this.REQUIRED_DATA_KEYS.forEach(key => {
			if (portalsDataElement.data(key) === undefined) {
				this._throwMissingDataAttrError(key);
			}
		});
	}

	static _getElement() {
		return $(".js-portals-data");
	}

	static _throwMissingDataAttrError(key) {
		throw new Error(`Data attribute "${key}" does not exist`);
	}

	constructor() {
		const portalsDataElement = EmployeePortalsData._getElement();
		this.setLanguageURL = portalsDataElement.data(EmployeePortalsData.SET_LANGUAGE_URL_KEY);
		this.activateTimezoneURL = portalsDataElement.data(EmployeePortalsData.ACTIVATE_TIMEZONE_URL_KEY);
		this.isAuthenticated = portalsDataElement.data(EmployeePortalsData.IS_AUTHENTICATED_KEY).toLowerCase() === "true";
		this.isOnboarded = portalsDataElement.data(EmployeePortalsData.IS_ONBOARDED_KEY).toLowerCase() === "true";
	}

	setIsOnboarded(value) {
		const element = EmployeePortalsData._getElement();
		element.attr("data-is-onboarded", value);
	}
}
