import Page from "./_page";
import { paintSVGs } from "../../_utils";

export class UserPreferencesPage extends Page {
	static PAGE_CLASS = "js-user-preferences-page";

	static LANGUAGE_FORM_SELECTOR = ".js-user-language-form";
	static SELECT_DROPDOWN_CARROT_SELECTOR = ".js-select-dropdown-carrot";
	static SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR = ".selectric .button";

	constructor() {
		super();
		paintSVGs();
		this._initiateSelectricField();
	}

	_initiateSelectricField() {
		const $form = $(UserPreferencesPage.LANGUAGE_FORM_SELECTOR);
		$form.find("select").selectric({
			maxHeight: 350,
			disableOnMobile: false,
			nativeOnMobile: false,
			onChange: function() {
				$(UserPreferencesPage.LANGUAGE_FORM_SELECTOR).submit();
			}
		});

		const $selectDropdownCarrot = $(UserPreferencesPage.SELECT_DROPDOWN_CARROT_SELECTOR);
		const selectricFields = $form.find(UserPreferencesPage.SELECT_DROPDOWN_CARROT_CONTAINER_SELECTOR);
		selectricFields.each((index, field) => $(field).append($selectDropdownCarrot.html()));
	}
}
