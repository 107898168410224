import $ from "jquery";
import axios from "axios";
import { getFormData } from "./_utils";

export class FormService {

	static FORM_FIELDS_CONTAINER_SELECTOR = ".js-form-fields-container";
	static GLOBAL_ERRORS_CONTAINER_SELECTOR = ".js-form-errors-container";
	static FORM_FIELD_SELECTOR = ".js-form-field";

	static FORM_FIELD_WITH_ERRORS_CSS_CLASS = "form-field--with-errors";
	static FORM_ERROR_CSS_CLASS = "form-error";
	static FORM_FIELD_ERROR_CSS_CLASS = "form-field-error";

	constructor(formSelector) {
		this._setup(formSelector);
		this._validateForm();
	}

	_setup(formSelector) {
		this.formSelector = formSelector;
		this.$form = $(this.formSelector);
	}

	_validateForm() {
		if (!this.$form.length) {
			throw Error(`Unable to find element with selector "${this.formSelector}"`);
		}
		if (!this.$form.find(FormService.FORM_FIELDS_CONTAINER_SELECTOR).length) {
			throw Error(
				`Form (${this.formSelector}) does not contain form fields container element`
				+ ` with selector "${FormService.FORM_FIELDS_CONTAINER_SELECTOR}"`
			);
		}
		if (!this.$form.find(FormService.FORM_FIELD_SELECTOR).length) {
			throw Error(
				`Form (${this.formSelector}) does not contain any form fields`
				+ ` with selector "${FormService.FORM_FIELD_SELECTOR}"`
			);
		}
	}

	async submit(url) {
		this._clearErrors();
		try {
			await axios.post(url || this.$form.attr("action"), this.getFormData());
		} catch (error) {
			this._renderErrors(error.response.data.errors);
		}
	};

	getFormData() {
		return getFormData(this.$form);
	}

	hasErrors() {
		return (
			this.$form.find(`.${FormService.FORM_ERROR_CSS_CLASS}`).length
			|| this.$form.find(`.${FormService.FORM_FIELD_ERROR_CSS_CLASS}`).length
			|| this.$form.find(FormService.FORM_FIELD_SELECTOR).hasClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS)
		);
	}

	_clearErrors() {
		this.$form.find(`.${FormService.FORM_ERROR_CSS_CLASS}`).remove();
		this.$form.find(`.${FormService.FORM_FIELD_ERROR_CSS_CLASS}`).remove();
		this.$form.find(FormService.FORM_FIELD_SELECTOR).removeClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
	}

	_renderErrors(errors = []) {
		for (const fieldName in errors) {
			const errorMessages = errors[fieldName];
			if (fieldName === "__all__") {
				this._renderFormError(errorMessages);
				continue;
			}

			const $input = this.$form.find(`input[name=${fieldName}`);
			if ($input.length) {
				this._renderInputError($input, errorMessages);
				continue;
			}

			const $select = this.$form.find(`select[name=${fieldName}`);
			if ($select.length) {
				this._renderSelectError($select, errorMessages);
			}
		}
	}

	_renderFormError(errorMessages) {
		const html = errorMessages
			.map(message => `<p class="${FormService.FORM_ERROR_CSS_CLASS}">${message}</p>`)
			.join("");
		const $formErrorsContainer = this.$form.find(FormService.GLOBAL_ERRORS_CONTAINER_SELECTOR);
		if ($formErrorsContainer.length) {
			$formErrorsContainer.append(html);
		} else {
			this.$form.find(FormService.FORM_FIELDS_CONTAINER_SELECTOR).after(html);
		}
		$(FormService.FORM_FIELD_SELECTOR).addClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
	}

	_renderInputError($input, errorMessages) {
		const $formField = $input.closest(FormService.FORM_FIELD_SELECTOR);
		$formField.addClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
		const html = errorMessages
			.map(message => `<p class="${FormService.FORM_FIELD_ERROR_CSS_CLASS}">${message}</p>`)
			.join("");
		$formField.append(html);
	}

	_renderSelectError($select, errorMessages) {
		const $formField = $select.closest(FormService.FORM_FIELD_SELECTOR);
		$formField.addClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
		const html = errorMessages
			.map(message => `<p class="${FormService.FORM_FIELD_ERROR_CSS_CLASS}">${message}</p>`)
			.join("");
		$formField.after(html);
	}

}
