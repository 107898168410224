import { isOnMobile, paintSVGs } from "../_utils";

class Modal {

	static MODAL_CONTAINER_SELECTOR = ".js-modal-container";
	static MODAL_BACKGROUND_SELECTOR = ".js-modal-background";
	static MODAL_SPINNER_BOX_SELECTOR = ".js-modal-spinner-box";
	static MODAL_SPINNER_MESSAGE_SELECTOR = ".js-modal-spinner-message";
	static MODAL_BOX_SELECTOR = ".js-modal-content-box";
	static MODAL_BOX_CONTENT_SELECTOR = ".js-modal-content-box__actual-content";
	static MODAL_CLOSE_BUTTON_SELECTOR = ".js-modal-close-button";
	static HIDDEN_CONTENT_PARENT_CLASS = "js-hidden-content";
	static ACTIVE_CLASS = "active";

	constructor() {
		this.$modalContainer = $(Modal.MODAL_CONTAINER_SELECTOR);
		this.$modalBackground = $(Modal.MODAL_BACKGROUND_SELECTOR);
		this.$modalSpinnerBox = $(Modal.MODAL_SPINNER_BOX_SELECTOR);
		this.$modalSpinnerMessage = $(Modal.MODAL_SPINNER_MESSAGE_SELECTOR);
		this.$modalBox = $(Modal.MODAL_BOX_SELECTOR);
		this.$modalBoxOriginalContent = this.$modalBox.html();
		this.spinnerModiffierClasses = [];
	}

	show() {
		this.hide();
		this.$modalContainer.addClass(Modal.ACTIVE_CLASS);
		isOnMobile() && $("header, main, footer").hide();
		return this;
	}

	showSpinner(message) {
		this.$modalSpinnerBox.addClass(Modal.ACTIVE_CLASS);
		if (message) {
			this.$modalSpinnerMessage.html(message);
			this.$modalSpinnerMessage.addClass(Modal.ACTIVE_CLASS);
		}
		return this;
	}

	showRedSpinner(message) {
		this.spinnerModiffierClasses.push("modal__spinner-box--red");
		this.$modalSpinnerBox.addClass(this.spinnerModiffierClasses);
		this.showSpinner(message);
	}

	showContentBox(content) {
		if (content && content.parent && content.parent().hasClass(Modal.HIDDEN_CONTENT_PARENT_CLASS)) {
			this.$currentOriginalBoxContent = content.clone();
		}
		this.$modalBox.find(Modal.MODAL_BOX_CONTENT_SELECTOR).html(content || this.$modalBoxOriginalContent);
		this.$modalBox.addClass(Modal.ACTIVE_CLASS);
		this._applyModalCloseButtonHandlers();
		paintSVGs();
		return this;
	}

	enableCloseOption() {
		this.$modalBackground.click(() => {
			this.hide();
			this.$modalBackground.off("click");
		});
		return this;
	}

	enableMobileFullScreen() {
		this.$modalBox.addClass("modal__box--full-screen-mobile");
		return this;
	}

	hide() {
		if (this.$currentOriginalBoxContent) {
			this._putOriginalBoxContentBackToHiddenContent();
			this.$modalBox.find(Modal.MODAL_BOX_CONTENT_SELECTOR).empty();
		}
		this.$modalContainer.removeClass(Modal.ACTIVE_CLASS);
		this.hideSpinner();
		this.hideBox();
		isOnMobile() && $("header, main, footer").show();
	}

	_putOriginalBoxContentBackToHiddenContent() {
		const $hiddenContent = $(`.${Modal.HIDDEN_CONTENT_PARENT_CLASS}`);
		$hiddenContent.append(this.$currentOriginalBoxContent);
		this.$currentOriginalBoxContent = undefined;
	}

	hideSpinner() {
		this.$modalSpinnerBox.removeClass([Modal.ACTIVE_CLASS, ...this.spinnerModiffierClasses]);
		this.spinnerModiffierClasses = [];
		this.$modalSpinnerMessage.removeClass(Modal.ACTIVE_CLASS);
		return this;
	}

	hideBox() {
		this.$modalBox.removeClass(Modal.ACTIVE_CLASS);
		return this;
	}

	isActive() {
		return this.$modalContainer.hasClass(Modal.ACTIVE_CLASS);
	}

	_applyModalCloseButtonHandlers() {
		const $modalCloseButtons = $(Modal.MODAL_CLOSE_BUTTON_SELECTOR);
		$modalCloseButtons.each((index, modalCloseButton) => $(modalCloseButton).click(() => this.hide()));
	}
}

export default Modal;
