import Page from "./_page";

export class LocationAvailabilitiesPage extends Page {

	static PAGE_CLASS = "js-location-availabilities-page";

	static LOCATION_AVAILABILITY_BAR_SELECTOR = ".js-location-availability-bar";
	static OCCUPIED_SPOTS_BAR_SELECTOR = ".js-occupied-spots-bar";

	static OCCUPIED_SPOTS_DATA_KEY = "occupiedSpots";
	static PHYSICAL_SPOTS_DATA_KEY = "physicalSpots";

	static EXPLANATORY_TEXT_SELECTOR = ".js-explanatory-text";
	static EXPLANATORY_TEXT_LINK_SHOW_SELECTOR = ".js-explanatory-text-link-show";
	static EXPLANATORY_TEXT_LINK_HIDE_SELECTOR = ".js-explanatory-text-link-hide";

	constructor() {
		super();
		this._initLocationAvailabilityBar();

		const linkSelectors = `
        ${LocationAvailabilitiesPage.EXPLANATORY_TEXT_LINK_SHOW_SELECTOR},
        ${LocationAvailabilitiesPage.EXPLANATORY_TEXT_LINK_HIDE_SELECTOR}
        `;
		$(linkSelectors).on("click", (e) => {
			e.preventDefault();
			$(e.currentTarget).toggleClass("hidden-element");
			$(e.currentTarget).siblings(linkSelectors).toggleClass("hidden-element");
			$(e.currentTarget)
				.siblings(LocationAvailabilitiesPage.EXPLANATORY_TEXT_SELECTOR)
				.toggleClass("hidden-element");
		});
	}

	_initLocationAvailabilityBar() {
		$(LocationAvailabilitiesPage.LOCATION_AVAILABILITY_BAR_SELECTOR).each((index, locationAvailabilityBar) => {
			const $locationAvailabilityBar = $(locationAvailabilityBar);
			const occupiedSpotsBarWidth = this._getOccupiedSpotsBarWidth($locationAvailabilityBar);
			const $occupiedSpotsBar = $locationAvailabilityBar
				.find(LocationAvailabilitiesPage.OCCUPIED_SPOTS_BAR_SELECTOR);
			$occupiedSpotsBar.css("width", `${occupiedSpotsBarWidth}%`);
		});
	}

	_getOccupiedSpotsBarWidth($locationAvailabilityBar) {
		const occupiedSpots = $locationAvailabilityBar.data(LocationAvailabilitiesPage.OCCUPIED_SPOTS_DATA_KEY);
		const physicalSpots = $locationAvailabilityBar.data(LocationAvailabilitiesPage.PHYSICAL_SPOTS_DATA_KEY);
		return occupiedSpots / physicalSpots * 100;
	}

}
