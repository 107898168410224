import ReservationService from "../_reservation_service";
import Page from "./_page";

export class MyReservationsPage extends Page {

	static PAGE_CLASS = "js-my-reservations-page";

	constructor() {
		super();
		const reservationService = new ReservationService();
		reservationService.applyWeekCollapseToggleHandlers();
		reservationService.collapseEmptyWeeks();
		reservationService.applyBlockSetCollapseToggleHandlers();
		reservationService.applyRemoveReservationBlockHandlers();
	}

}
