import $ from "jquery";
import axios from "axios";

export class Timezone {

	constructor() {
		this.shouldReloadPage = false;
		const portalsData = $(".js-portals-data").data();
		this.activateTimezoneURL = portalsData.activateTimezoneUrl;
	}

	async activate(timezoneName) {
		const data = { timezone: timezoneName };
		const response = await axios.post(this.activateTimezoneURL, data);
		this.shouldReloadPage = response.data.current_timezone !== response.data.previous_timezone;
	}

}

export const timezone = new Timezone();
