import Page from "./_page";
import { LicensePlatesFormService } from "../_license_plates_form_service";

export class LicensePlatePage extends Page {

	static PAGE_CLASS = "js-license-plates-page";


	constructor() {
		super();
		const licensePlatesFormService = new LicensePlatesFormService();
		licensePlatesFormService.allowEmptyForm();
	}

}
