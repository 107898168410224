import { ReservationPage } from "./_reservation_page";
import { MyReservationsPage } from "./_my_reservations_page";
import { ReservationHistoryPage } from "./_reservation_history_page";
import { LocationAvailabilitiesPage } from "./_location_availabilities_page";
import { PaymentPage } from "./_payment_page";
import { BalancePage } from "./_balance_page";
import { TransactionsPage } from "./_transactions_page";
import { FaqPage } from "./_faq_page";
import { LicensePlatePage } from "./_license_plate_page";
import { UserPreferencesPage } from "./_preferences_page";
import { TwoFALoginPage } from "./_two_fa_login_page";

export const regularPageClasses = [
	MyReservationsPage,
	LocationAvailabilitiesPage,
	PaymentPage,
	BalancePage,
	TransactionsPage,
	FaqPage,
	LicensePlatePage,
	UserPreferencesPage,
	TwoFALoginPage
];

export const asyncPageClasses = [
	ReservationPage,
	ReservationHistoryPage
];
