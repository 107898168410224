export class MobileService {

	static isAndroid() {
		return /android/i.test(navigator.userAgent);
	}

	static isIOS() {
		return (
			/iPad|iPhone|iPod/.test(navigator.userAgent)
			|| (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
		);
	}

	static isSafariOnIOS() {
		// Checks if "Safari" is present in the user agent string using a regular expression.
		// Additionally, include negative lookaheads for other common iOS browsers like
		// Chrome (CriOS), Firefox (FxiOS), Opera (OPiOS), and Mercury (mercury).
		// This helps exclude other browsers that may also contain "Safari" in their
		// user agent strings but are not actually Safari.
		const userAgent = navigator.userAgent;
		return this.isIOS() && /Safari/i.test(userAgent) && !/CriOS|FxiOS|OPiOS|mercury/i.test(userAgent);
	}

}
